import { useState } from 'react';
import api from '../../generalComponents/api';
import DOMPurify from 'dompurify';
import { Masks } from '../../Masks/masks';

export default function SingIn() {
  const [emailSignIn, setEmailSignIn] = useState('');
  const [isEmailValidSignIn, setIsEmailValidSignIn] = useState(true);
  const [passwordSignIn, setPasswordSignIn] = useState('');
  const [isPasswordValidSignIn, setIsPasswordValidSignIn] = useState(true);
  const [completeName, setCompleteName] = useState('');
  const [isCompleteNameValid, setIsCompleteNameValid] = useState(true);
  const [emailSignUp, setEmailSignUp] = useState('');
  const [isEmailValidSignUp, setIsEmailValidSignUp] = useState(true);
  const [passwordSignUp, setPasswordSignUp] = useState('');
  const [isPasswordValidSignUp, setIsPasswordValidSignUp] = useState(true);
  const [phone, setPhone] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  //states auxiliares
  const [enableAcessarButton, setEnableAcessarButton] = useState(true);
  const [enableCadastrarButton, setEnableCadastrarButton] = useState(true);
  const masks = new Masks();

  // IMPLEMENTAR LOGIN
  const signIn = async () => {
    setEnableAcessarButton(false);
    if (emailSignIn == '') {
      setIsEmailValidSignIn(false);
      setErrorMessage('Preencha todos os campos!');
      if (passwordSignIn == '') {
        setIsPasswordValidSignIn(false);
      }
      return;
    }
    if (passwordSignIn == '') {
      setIsPasswordValidSignIn(false);
      setErrorMessage('Preencha todos os campos!');
      setEnableAcessarButton(true);
      return;
    }
    if (!isEmailValidSignIn || !isPasswordValidSignIn) {
      setErrorMessage('Preencha todos os campos!');
      setEnableAcessarButton(true);
      return;
    }

    // IMPLEMENTAR LOGIN
    if (typeof document == 'undefined') return;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    setEmailSignIn(DOMPurify.sanitize(emailSignIn));
    setPasswordSignIn(DOMPurify.sanitize(passwordSignIn));

    const data = {
      email: emailSignIn,
      password: passwordSignIn,
    };

    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const signInResponse = await api
      .post('/signin', data)
      .then(async (response) => {
        const token = response.data.token;
        localStorage.setItem('appSession', token);
        await redirectLogin(token);
      })
      .catch((error) => {
        setEnableAcessarButton(true);
        // VERIFIQUE O EMAIL E A SENHA
        setIsEmailValidSignIn(false);
        setIsPasswordValidSignIn(false);
        setErrorMessage('Email e(ou) senha inválida! Verifique o(s) campo(s).');
      });
  };

  const redirectLogin = async (token: string) => {
    if (typeof window == 'undefined') return;
    if (typeof document == 'undefined') return;
    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;

    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const clientResponse = await api
      .get('/client/authorizate', { params: { email: emailSignIn } })
      .then((response) => {
        if (response.data.config.dominio == 'empty') {
          window.alert(
            'Cadastro ainda não concluído! Entraremos em contato...',
          );
        } else {
          location.href = `//${response.data.config.dominio}/session?token=${token}`;
        }
      })
      .catch((error) => {
        // PROBLEMA AO LOCALIZAR CADASTRO
        window.alert('Problema ao localizar cadastro!');
      });
  };

  // IMPLEMENTAR CADASTRO
  const signUp = async () => {
    setEnableCadastrarButton(false);
    if (completeName == '') setIsCompleteNameValid(false);
    if (emailSignUp == '') setIsEmailValidSignUp(false);
    if (passwordSignUp == '') setIsPasswordValidSignUp(false);
    if (phone == '') setIsPhoneValid(false);
    if (phone.length != 15) setIsPhoneValid(false);
    if (
      !isCompleteNameValid ||
      !isEmailValidSignUp ||
      !isPasswordValidSignUp ||
      !isPhoneValid
    ) {
      setEnableCadastrarButton(true);
      return;
    }

    // IMPLEMENTAR CADASTRO
    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    setEmailSignUp(DOMPurify.sanitize(emailSignUp));
    setPasswordSignUp(DOMPurify.sanitize(passwordSignUp));

    const data = {
      email: emailSignUp,
      password: passwordSignUp,
      role: 'promotor'
    };

    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const signUpResponse = await api
      .post('/signup', data)
      .then(async (response) => {
        await addClient();
      })
      .catch((error) => {
        setEnableCadastrarButton(true);
        if (
          error.response.data ==
          'auth/email-already-in-use: Firebase: Error (auth/email-already-in-use).'
        ) {
          window.alert('Email já cadastrado!');
        } else {
          window.alert('Falha ao realizar pré-cadastro!');
        }
      });
  };

  const addClient = async () => {
    if (typeof window == 'undefined') return;
    if (typeof document == 'undefined') return;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;

    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const data = {
      config: {
        cnpj: 'empty',
        dominio: 'empty',
        analytics: {
          googleAnalytics: 'empty',
          googleGTM: 'empty',
          pixelID: 'empty',
        },
        mensagens: {
          qtd0: 'Adquira já!',
          qtd50: 'Já foi metade!',
          qtd70: 'Corre que está acabando!',
          qtd90: 'Últimos 10%!',
          qtdAcima90: 'Reta final!',
          finalizado: 'FINALIZADO',
          aguardando: 'AGUARDANDO',
          posHora: 'POS HORA',
        },
        textosProdutos: {
          descricao: 'empty',
          subtituloDescricao: 'empty',
          promocao: 'empty',
          subtituloPromocao: 'empty',
          cotas: 'empty',
          subtituloCotas: 'empty',
          botaoMeusNumeros: 'empty',
          qtdNumeros: 'empty',
          botaoCompra: 'empty',
          texto1Checkout: 'empty',
          texto2Checkout: 'empty',
          texto3Checkout: 'empty',
          semEstoque: 'empty',
        },
        textosInicial: {
          titulo: 'empty',
          subtitulo: 'empty',
        },
        textosMeusNumeros: {
          titulo: 'empty',
          subtitulo: 'empty',
        },
        textosGanhadores: {
          titulo: 'empty',
          subtitulo: 'empty',
        },
        textosSorteios: {
          titulo: 'empty',
          subtitulo: 'empty',
        },
        rodape: {
          logoCapitalizadora: 'empty',
          logoBeneficiario: 'empty',
          texto: 'empty',
        },
        redesSociais: {
          whatsapp: 'empty',
          instagram: 'empty',
          facebook: 'empty',
          telegram: 'empty',
        },
        perguntas: 'empty',
        iconeNavegador: 'empty',
        logo: 'empty',
        contas: {
          mercadoPago: 'empty',
          efi: 'empty',
        },
      },
      dados: {
        nome: DOMPurify.sanitize(completeName),
        email: DOMPurify.sanitize(emailSignUp),
        telefone: DOMPurify.sanitize(phone),
        cpf: '123.456.789-01',
        nivel: 'empty',
        faturamento: 'empty',
        qtdSorteiosCriados: 'empty',
        cotasVendidas: 'empty',
        usuarios: 'empty',
        qtdPedidos: 'empty',
      },
    };

    const addClientResponse = await api
      .post('/client/newSallesAccount', data)
      .then((response) => {
        // SUCESSO NO CADASTRO
        window.alert(
          'Pré-cadastro realizado! Entraremos em contato em breve...',
        );
      })
      .catch((error) => {
        console.log(error);
        window.alert('Falha ao realizar pré-cadastro!');
      });
  };

  const maskPhone = (value: string) => {
    value = value.replace(/\D/g, ''); //Remove tudo o que não é dígito
    value = value.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
    value = value.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
    setPhone(value);
  };

  return (
    <>
      <div className="m-16 w-96 lg:w-2/3 h-fit max-w-full max-h-full flex flex-col bg-white shadow-xl rounded-xl items-center">
        <div className="flex flex-row w-full mt-5 justify-between items-center">
          <a className="flex flex-row w-fit" href="/">
            <span className="ml-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 21 21"
              >
                <path
                  fill="none"
                  stroke="#b85900"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                />
              </svg>
            </span>
            <span className="text-orange-700 montserrat-400 m-1">Voltar</span>
          </a>
          <img
            className="w-12 h-12 mr-5"
            src="https://www.digitallucky.com.br/img/logo_digital_lucky_sem.png"
            alt="logo.png"
          />
        </div>
        <div className="py-5 px-8 w-full flex flex-col lg:flex-row">
          <div className="p-2 w-96 lg:w-full h-full max-w-full max-h-full flex flex-col text-center justify-center lg:border-r-2 mb-2 lg:border-orange-100">
            <h1 className="text-orange-700 text-2xl m-10">Entrar</h1>
            <input
              className={`my-2 w-full max-w-full h-12 bg-gray-50 shadow-inner p-3 rounded-md ${isEmailValidSignIn ? '' : 'border-2 border-red-500'}`}
              autoFocus
              type="text"
              placeholder="E-mail"
              value={emailSignIn}
              onChange={(e) => {
                setEmailSignIn(masks.emailMask(e.target.value));
                setIsEmailValidSignIn(true);
              }}
            />
            <input
              className={`my-2 w-full max-w-full h-12 bg-gray-50 shadow-inner p-3 rounded-md ${isPasswordValidSignIn ? '' : 'border-2 border-red-500'}`}
              type="password"
              placeholder="Senha"
              value={passwordSignIn}
              onChange={(e) => {
                setPasswordSignIn(e.target.value);
                setIsPasswordValidSignIn(true);
              }}
            />
            <div className="flex flex-col w-full justify-center items-center mb-60">
              {enableAcessarButton ? (
                <>
                  <button
                    className="bg-orange-700 py-1 rounded-full w-2/3 text-white montserrat-600"
                    onClick={(e) => signIn()}
                  >
                    Acessar
                  </button>
                </>
              ) : (
                <>
                  <button className="flex flex-row justify-center items-center bg-orange-800 py-1 rounded-full w-2/3 text-white montserrat-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.2em"
                      height="1.2em"
                      viewBox="0 0 24 24"
                      className="mr-2"
                    >
                      <path
                        fill="white"
                        d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"
                        opacity="0.5"
                      />
                      <path
                        fill="white"
                        d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z"
                      >
                        <animateTransform
                          attributeName="transform"
                          dur="0.75s"
                          from="0 12 12"
                          repeatCount="indefinite"
                          to="360 12 12"
                          type="rotate"
                        />
                      </path>
                    </svg>
                    Acessar
                  </button>
                </>
              )}

              {!isEmailValidSignIn || !isPasswordValidSignIn ? (
                <span className="text-red-500 font-light mt-2">
                  {errorMessage}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="p-2 w-96 lg:w-full h-full max-w-full max-h-full flex flex-col text-center justify-center">
            <h1 className="text-orange-700 text-2xl m-10">Criar conta</h1>
            <input
              className={`my-2 w-full max-w-full h-12 bg-gray-50 shadow-inner p-3 rounded-md ${isCompleteNameValid ? '' : 'border-2 border-red-500'}`}
              type="text"
              placeholder="Nome completo"
              onChange={(e) => {
                setCompleteName(e.target.value);
                setIsCompleteNameValid(true);
              }}
              value={completeName}
            />
            <input
              className={`my-2 w-full max-w-full h-12 bg-gray-50 shadow-inner p-3 rounded-md ${isEmailValidSignUp ? '' : 'border-2 border-red-500'}`}
              type="text"
              placeholder="E-mail"
              onChange={(e) => {
                setEmailSignUp(e.target.value);
                setIsEmailValidSignUp(true);
              }}
              value={emailSignUp}
            />
            <input
              className={`my-2 w-full max-w-full h-12 bg-gray-50 shadow-inner p-3 rounded-md ${isPasswordValidSignUp ? '' : 'border-2 border-red-500'}`}
              type="password"
              placeholder="Senha"
              onChange={(e) => {
                setPasswordSignUp(e.target.value);
                setIsPasswordValidSignUp(true);
              }}
              value={passwordSignUp}
            />
            <input
              className={`my-2 w-full max-w-full h-12 bg-gray-50 shadow-inner p-3 rounded-md ${isPhoneValid ? '' : 'border-2 border-red-500'}`}
              type="text"
              placeholder="Telefone"
              maxLength={15}
              onChange={(e) => {
                maskPhone(e.target.value);
                if (e.target.value.length == 15) setIsPhoneValid(true);
              }}
              value={phone}
            />
            <div className="w-full flex flex-col justify-center text-center">
              <p className="text-xs my-1 mr-2">Realize seu pré-cadastro</p>
              <p className="text-xs mr-2">
                Em até 24 horas entraremos em contato para mais informações!
              </p>
            </div>
            <div className="flex flex-col h-full mb-10 w-full justify-center items-center">
              {enableCadastrarButton ? (
                <>
                  <button
                    className="mt-2 bg-orange-700 py-1 rounded-full w-2/3 text-white montserrat-600"
                    onClick={(e) => signUp()}
                  >
                    Cadastrar
                  </button>
                </>
              ) : (
                <>
                  <button className="mt-2 flex flex-row justify-center items-center bg-orange-800 py-1 rounded-full w-2/3 text-white montserrat-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.2em"
                      height="1.2em"
                      viewBox="0 0 24 24"
                      className="mr-2"
                    >
                      <path
                        fill="white"
                        d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z"
                        opacity="0.5"
                      />
                      <path
                        fill="white"
                        d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z"
                      >
                        <animateTransform
                          attributeName="transform"
                          dur="0.75s"
                          from="0 12 12"
                          repeatCount="indefinite"
                          to="360 12 12"
                          type="rotate"
                        />
                      </path>
                    </svg>
                    Cadastrar
                  </button>
                </>
              )}

              {!isCompleteNameValid ||
              !isEmailValidSignUp ||
              !isPasswordValidSignUp ||
              !isPhoneValid ? (
                <span className="text-red-500 font-light mt-2">
                  {errorMessage}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
